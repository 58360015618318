import axios from "axios";
import config from '../config.json';

//production
 const ROOT_URL = "https://api-mscw.transfinmational.net";
//  const ROOT_URL = "http://localhost:3334";
 const ROOT_URL2 = config.PROD_API;

export const getCollectorExport= (token, body) => {
    return axios.post(`${ROOT_URL}/hubspot/contacts/export`, body, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}
import * as React from 'react';
// import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarIcon from '@mui/icons-material/Star';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Link from '@mui/material/Link';

export default function NestedList() {
    let menus = JSON.parse(localStorage.getItem('menus'))

    const [isShownCMS, setIsShownCMS] = React.useState(false);
    const [isShownCoreLoan, setIsShownCoreLoan] = React.useState(false);
    const [isShownStatement, setIsShownStatement] = React.useState(false);
    const [isShownSetting, setIsShownSetting] = React.useState(false);
    const [isShownUserManangement, setIsShownUserManangement] = React.useState(false);
    const [isShownUserPermission, setIsShownUserPermission] = React.useState(false);

    const handleClick = (e) => {
        switch (e) {
            case 1:
                setIsShownCMS(current => !current);

                setIsShownCoreLoan(false)
                setIsShownStatement(false)
                setIsShownSetting(false)
                setIsShownUserManangement(false)
                setIsShownUserPermission(false)
                break;
            case 2:
                setIsShownCoreLoan(current => !current);

                setIsShownCMS(false)
                setIsShownStatement(false)
                setIsShownSetting(false)
                setIsShownUserManangement(false)
                setIsShownUserPermission(false)
                break;
            case 3:
                setIsShownStatement(current => !current);

                setIsShownCMS(false)
                setIsShownCoreLoan(false)
                setIsShownSetting(false)
                setIsShownUserManangement(false)
                setIsShownUserPermission(false)
                break;
            case 4:
                setIsShownSetting(current => !current);

                setIsShownCMS(false)
                setIsShownCoreLoan(false)
                setIsShownStatement(false)
                setIsShownUserManangement(false)
                setIsShownUserPermission(false)
                break;
            case 5:
                setIsShownUserManangement(current => !current);

                setIsShownCMS(false)
                setIsShownCoreLoan(false)
                setIsShownStatement(false)
                setIsShownSetting(false)
                setIsShownUserPermission(false)
                break;
            case 6:
                setIsShownUserPermission(current => !current);

                setIsShownCMS(false)
                setIsShownCoreLoan(false)
                setIsShownStatement(false)
                setIsShownSetting(false)
                setIsShownUserManangement(false)
                break;
            default:
        }
    };

    return (
        <>
            <List
            >
                <ListItemButton onClick={() => handleClick(1)} style={{ display: menus[0]?.access === 'true' ? '' : 'none' }}>
                    <ListItemIcon>
                        <StarIcon />
                    </ListItemIcon>
                    <ListItemText primary="CMS" />
                    {isShownCMS ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={isShownCMS} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Link href="/contact_management" color="inherit" underline="none">
                            <ListItemButton sx={{ pl: 9 }} style={{ display: menus[1]?.access === 'true' ? '' : 'none' }}>
                                <ListItemText primary="Contact Management" />
                            </ListItemButton>
                        </Link>

                        <Link href="/contact_collector" color="inherit" underline="none" >
                            <ListItemButton sx={{ pl: 9 }} style={{ display: menus[2]?.access === 'true' ? '' : 'none' }}>
                                <ListItemText primary="Collector Management" />
                            </ListItemButton>
                        </Link>

                        <ListItemButton sx={{ pl: 9 }} style={{ display: menus[3]?.access === 'true' ? '' : 'none' }}>
                            <ListItemText primary="Report" />
                        </ListItemButton>

                    </List>
                </Collapse>

                {/* <ListItemButton onClick={() => handleClick(2)} >
                    <ListItemIcon>
                        <StarIcon />
                    </ListItemIcon>
                    <ListItemText primary="Core Loan" />
                    {isShownCoreLoan ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={isShownCoreLoan} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 9 }}>
                            <ListItemText primary="developing" style={{ color: '#ABB2B9' }} />
                        </ListItemButton>
                    </List>
                </Collapse> */}

                <ListItemButton onClick={() => handleClick(3)} style={{ display: menus[4]?.access === 'true' ? '' : 'none' }}>
                    <ListItemIcon>
                        <StarIcon />
                    </ListItemIcon>
                    <ListItemText primary="Statement" />
                    {isShownStatement ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={isShownStatement} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding >
                        <ListItemButton sx={{ pl: 9 }} >
                            <ListItemText primary="developing *" style={{ color: '#ABB2B9' }} />
                        </ListItemButton>
                    </List>
                </Collapse>
                

                {/* <Link href="" color="inherit" underline="none"> */}
                <ListItemButton style={{ display: menus[5]?.access === 'true' ? '' : 'none' }}>
                    <ListItemIcon>
                        <ReceiptIcon />
                    </ListItemIcon>
                    <ListItemText primary="NCB Reader" />
                </ListItemButton>
                {/* </Link> */}

                <ListItemButton onClick={() => handleClick(4)} style={{ display: menus[6]?.access === 'true' ? '' : 'none' }}>
                    <ListItemIcon>
                        <StarIcon />
                    </ListItemIcon>
                    <ListItemText primary="Setting" />
                    {isShownSetting ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={isShownSetting} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 9 }} style={{ display: menus[7]?.access === 'true' ? '' : 'none' }}>
                            <ListItemText primary="NCB Scoring Setup" />
                        </ListItemButton>
                    </List>
                </Collapse>

                <ListItemButton onClick={() => handleClick(5)} style={{ display: menus[8]?.access === 'true' ? '' : 'none' }}>
                {/* <ListItemButton onClick={() => handleClick(5)} > */}
                    <ListItemIcon>
                        <StarIcon />
                    </ListItemIcon>
                    <ListItemText primary="User Management" />
                    {isShownUserManangement ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={isShownUserManangement} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Link href="/user_management" color="inherit" underline="none">
                            {/* <ListItemButton sx={{ pl: 9 }}> */}
                            <ListItemButton sx={{ pl: 9 }} style={{ display: menus[9]?.access === 'true' ? '' : 'none' }}>
                                <ListItemText primary="User Management" />
                            </ListItemButton>
                        </Link>
                    </List>
                    <List component="div" disablePadding>
                    <Link href="/user_role_management" color="inherit" underline="none">
                        {/* <ListItemButton sx={{ pl: 9 }} > */}
                        <ListItemButton sx={{ pl: 9 }} style={{ display: menus[10]?.access === 'true' ? '' : 'none' }}>
                            <ListItemText primary="User Role Management" />
                        </ListItemButton>
                        </Link>
                    </List>
                </Collapse>
                <ListItemButton onClick={() => handleClick(6)} style={{ display: menus[11]?.access === 'true' ? '' : 'none' }}>
                    <ListItemIcon>
                        <StarIcon />
                    </ListItemIcon>
                    <ListItemText primary="User Permission Management" />
                    {isShownUserPermission ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={isShownUserPermission} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 9 }} style={{ display: menus[12]?.access === 'true' ? '' : 'none' }}>
                            <ListItemText primary="System Menu Management" />
                        </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 9 }} style={{ display: menus[13]?.access === 'true' ? '' : 'none' }}>
                            <ListItemText primary="User Role Permission Management" />
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>

            <Link href="/login" color="inherit" underline="none">
                <ListItemButton style={{ position: 'absolute', bottom: 10, width: '100%' }} onClick={() => handleClick()}>
                    <ListItemIcon>
                        <StarIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItemButton>
            </Link>
        </>
    );
}

import axios from "axios";
import config from '../config.json';


//production
 const ROOT_URL = "https://api-mscw.transfinmational.net";
 const ROOT_URL2 = config.PROD_API;
//dev
// const ROOT_URL = "http://localhost:3334";
// const ROOT_URL = config.DEV_API;


export const createUser = (value, token, callback) => {
    console.log(token)
    return axios.post(`${ROOT_URL}/user/create`, value, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};

export const insert = (object, token, callback) => {
    return axios.post(`${ROOT_URL}/user/insert`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};

export const del = (object, token, callback) => {
    console.log(object)
    return axios.get(`${ROOT_URL}/user/delete/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};


// export const createData = (value, token, callback) => {
//     let object = JSON.stringify(value)
//     return axios.post(`${ROOT_URL}/user/create/data`, object, {
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': 'Bearer ' + token
//         },
//     })
// };

export const showUser = (object, token, callback) => {
    console.log(token)
    return axios.get(`${ROOT_URL}/user/get_data/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};

export const checkID = (object, token, callback) => {
    return axios.get(`${ROOT_URL}/user/ncb_id/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};

export const detail1 = (object, token, callback) => {
    return axios.get(`${ROOT_URL}/user/get_data/mscw/detail_1/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};

export const detail2 = (object, token, callback) => {
    return axios.get(`${ROOT_URL}/user/get_data/mscw/detail_2/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}

export const detail3 = (object, token, callback) => {
    return axios.get(`${ROOT_URL}/user/get_data/mscw/detail_3/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}

export const detail4 = (object, token, callback) => {
    return axios.get(`${ROOT_URL}/user/get_data/mscw/detail_4/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}

export const summarize = (object, token, callback) => {
        return axios.get(`${ROOT_URL}/user/get_data/mscw/sum/by_contact_id/` + object, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
    }
    

export const getContactID = (object, token, callback) => {
    return axios.get(`${ROOT_URL}/hubspot/contacts/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}

export const hubspotContact = (object, token, callback) => {
    console.log(token)
    return axios.get(`${ROOT_URL}/hubspot/contact/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
}

export const register = (object, token, callback) => {
    return axios.post(`${ROOT_URL}/user/register`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};

export const login = (object, token, callback) => {
    return axios.post(`${ROOT_URL}/user/login`, object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};
export const getUser = (object, token) => {
    return axios.get(`${ROOT_URL}/user/get_user/` + object, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};

export const getScore = () => {
    return axios.get(`${ROOT_URL}/ncb/score/`, {
    })
}


// export const authen = (token, callback) => {
//     return axios.post(`${ROOT_URL}/user/authen`, {
// headers: {
//     'Content-Type': 'application/json',
//     'Authorization': 'Bearer ' + token
// },
//     })
// };




export const UserLogin = () => {
    let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTYsImlhdCI6MTYzNTg3NjgwMCwiZXhwIjoxNjM1OTYzMjAwfQ.bi1sq3xEUz6cGvS4NHCFun1tOXGOx-Fcby2qC2XBEVo'

    return axios.get(`${ROOT_URL2}/users`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};
export const getUsers = (token) => {
    return axios.get(`${ROOT_URL2}/users`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};
export const getManagement = (token, body) => {
    return axios.post(`${ROOT_URL2}/contacts/management/`, body, {
        headers: {
            'Authorization': 'Bearer ' + token
        },
    })
}

export const getCollector = (token, body) => {
    return axios.post(`${ROOT_URL2}/contacts/collector`, body, {
        headers: {
            'Authorization': 'Bearer ' + token
        },
    })
}

export const assignCollector = (token, id, AssignUserID) => {
    let body = {
        "PolicyID": id,
        "AssignUserID": AssignUserID,
        "Description": ""
    }
    return axios.post(`${ROOT_URL2}/contacts/assign`, body, {
        headers: {
            'Authorization': 'Bearer ' + token
        },
    })
}

export const collectorAction = (token, id, action, ActionNewOnDate) => {
    let body = {
        "ID": id,
        "TypeID": action,
        "Remark": "",
        "ActionNewOnDate": ActionNewOnDate
    }
    return axios.post(`${ROOT_URL2}/contacts/colloterAction`, body, {
        headers: {
            'Authorization': 'Bearer ' + token
        },
    })
}

export const getActionType = (token) => {
    return axios.get(`${ROOT_URL2}/actionType`, {
        headers: {
            'Authorization': 'Bearer ' + token
        },
    })
}

export const getUserCollector = (token) => {
    return axios.get(`${ROOT_URL2}/users`, {
        headers: {
            'Authorization': 'Bearer ' + token
        },
    })
}

export const getContactActivity = (token) => {
    return axios.get(`${ROOT_URL2}/contact/9/activity`, {
        headers: {
            'Authorization': 'Bearer ' + token
        },
    })
}


export const getContactDetail = (token, id) => {
    return axios.get(`${ROOT_URL2}/contact/` + id, {
        headers: {
            'Authorization': 'Bearer ' + token
        },
    })
}
export const addRemark = (data,token) => {
    return axios.post(`${ROOT_URL2}/contacts/updateAction`,data,{
        headers: {
            'Authorization': 'Bearer ' + token
        },
    })
}

import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { read, utils, WorkSheet } from 'xlsx';

import {
  callOCRStatement
} from '../../services/statement'

const UploadPDFStatement = ({ mode }) => {
  const [bank, setBank] = useState(0);
  const [password, setPassword] = useState("");
  const [pdf_file, setPDFfile] = useState([])
  // const [formData, setFormData] = useSt
  const [data_model, setDataModel] = useState([]);
  const fileInput = useRef(null);
  
  const contact_id = "11292"

  const handleBankChange = (event) => {
    setBank(event.target.value);
  };

  const handleSave = () => {
    console.log(pdf_file);
    if(bank === 0){
      alert("Please select bank")
    }else{
      let bank_name = "";
      switch(bank) {
        case 1:
          // code block
          bank_name = 'kbank'
          break;
        case 2:
          // code block
          bank_name = 'scb'
          break;
        default:
          // code block
      }
      const formData = new FormData();
      formData.append(
        "files",
        pdf_file,
        pdf_file.name
      );
      formData.append(
        "bank",
        bank_name
      );
      formData.append(
        "password",
        password
      );
      formData.append(
        "callback",
        "https://api-uat.transfinmational.net/ocrstatement?id="+contact_id
      );
      // console.log(formData);
      console.log(...formData) // shortest script solution
      callOCRStatement(formData).then(res=>{
        console.log(res.data);
        if(res.data.success){
          alert("คุณได้ทำการ upload ข้อมูล กรุณารอการประมวลผลซักครู่")
          window.location.reload();
        }
      }).catch(err => {
        console.log(err);
        // setNewsThumbnail("")
      })
    }
  }

  const handleClick = () => {
    fileInput.current.value = '';
    window.location.reload(false)
  }

  const onFileBodyChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      // handle unselect option 
      setPDFfile(event.target.files[0])
      // if(bank === 0){
      //   alert("Please select bank")
      // }else{
      //   let bank_name = "";
      //   switch(bank) {
      //     case 1:
      //       // code block
      //       bank_name = 'kbank'
      //       break;
      //     case 2:
      //       // code block
      //       bank_name = 'scb'
      //       break;
      //     default:
      //       // code block
      //   }
      //   const formData = new FormData();
      //   formData.append(
      //     "file",
      //     event.target.files[0],
      //     event.target.files[0].name
      //   );
      //   formData.append(
      //     "bank",
      //     bank_name
      //   );
      //   formData.append(
      //     "password",
      //     password
      //   );
      //   formData.append(
      //     "callback",
      //     "https://api-uat.transfinmational.com/statement?id="+contact_id
      //   );
      //   // console.log(formData);
      //   console.log(...formData) // shortest script solution
      //   callOCRStatement(formData).then(res=>{
      //     console.log(res);
      //   }).catch(err => {
      //     console.log(err);
      //     // setNewsThumbnail("")
      //   })
      // }
    }
  }

  return (
    <Card style={{ marginTop: '1.5rem', marginRight: '10%', marginLeft: '10%' }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={bank}
              onChange={handleBankChange}
              fullWidth
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
              }}
            >
              <MenuItem key={0} value={0}>กรุณาเลือกธนาคาร</MenuItem>
              <MenuItem value={1}>ธ.กสิกรไทย</MenuItem>
              {/* <MenuItem value={2}>ธ.ทหารไทยธนชาต</MenuItem> */}
              {/* <MenuItem value={3}>ธ.กรุงเทพ</MenuItem> */}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="ไฟล์ Statement"
              // value={news_thumbnail} 
              onChange={event => onFileBodyChange(event)}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                backgroundColor: "#FFFFFF"
              }}
              type="file"
              ref={fileInput}
              inputProps={{ accept: 'application/pdf' }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="PDF password"
              variant="outlined"
              value={password}
              onChange={event => setPassword(event.target.value)}
            />
          </Grid>
          
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item ></Grid>
          <Grid item>
            {/* <Button variant="contained" color="success" onClick={}>{button_success}</Button> */}
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={handleSave} >SAVE</Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="error" onClick={handleClick} >CLOSE</Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}
export default UploadPDFStatement;
